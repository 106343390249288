<!-- For this Carousel, it needs to have it's CarouselItem as it's child component
For example

<Carousel>
  <CarouselItem>{...}</CarouselItem>
  <CarouselItem>{...}</CarouselItem>
  <CarouselItem>{...}</CarouselItem>
  <CarouselItem>{...}</CarouselItem>
<Carousel>
-->

<script>
  import { Splide, SplideTrack } from '@splidejs/svelte-splide'
  import { Chevron } from '@/components/icons'
  import Tracker from '@/util/tracker.js'

  export let showPagination = true
  export let arrowColor = 'green'
  export let showArrows = false
  export let loop = false
  export let arrowPosition = null
  export let splideOptions = {}
  export let paginationColor = null
  export let carouselRef = null
  // arrowSize = 'md', 'lg'
  export let arrowSize = 'md'

  const arrowClassList = {
    lg: 'large-arrow',
    xl: 'xlarge-arrow',
  }

  const arrowClass = arrowClassList[arrowSize]
</script>

<style>
  .custom-button {
    background-color: transparent;
    padding-left: 4px;
    padding-right: 4px;
  }

  .custom-button.top-arrow {
    top: 9%;
  }

  @media only screen and (width >= 768px) {
    .custom-button.top-arrow {
      top: 50%;
    }
  }
</style>

<Splide
  hasTrack={false}
  bind:this={carouselRef}
  options={{
    arrows: showArrows,
    pagination: showPagination,
    type: loop && 'loop',
    classes: {
      page: `pagination-button ${paginationColor === 'green' ? 'green' : ''}`,
      prev: 'splide__arrow--prev splide-arrow-prev',
      next: 'splide__arrow--next splide-arrow-next',
      arrow: arrowClass,
    },
    ...splideOptions,
  }}
>
  <div class="splide__arrows">
    <button
      class="custom-button {arrowPosition
        ? 'top-arrow'
        : ''} splide__arrow splide__arrow--prev hover:cursor-pointer splide-arrow {arrowClass} splide-arrow-prev"
      class:white={arrowColor === 'white'}
      on:click={() => Tracker.track('LP BuyBox left arrow clicked')}
    >
      <Chevron direction="left" />
    </button>

    <button
      class="custom-button splide__arrow splide__arrow--next hover:cursor-pointer splide-arrow {arrowClass} splide-arrow-next {arrowPosition
        ? 'top-arrow'
        : ''}"
      class:white={arrowColor === 'white'}
      on:click={() => Tracker.track('LP BuyBox right arrow clicked')}
    >
      <Chevron direction="right" />
    </button>
  </div>

  <SplideTrack>
    <slot />
  </SplideTrack>
  {#if showPagination}
    <div class="my-4">
      <ul class="splide__pagination splide-pagination" />
    </div>
  {/if}
</Splide>
